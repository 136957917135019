import { Collapse, IconButton } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowRight } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { MdAnalytics } from "react-icons/md";
//import MenuActions from "./MenuActions";
import { Menu, MenuItem } from "@material-ui/core";
import { Link } from "react-router-dom";
import {
  editorNavigationUrls,
  otherProtectedUrls,
  unprotectedUrls,
} from "../../../common/utils/lists";
import { handleRoleActionAccess } from "../../../common/utils/userRoleEvaluation";
import { APPS_CONTROL_MODES } from "../../../EditorLayout/Pages/Workflow/components/utils/constants";

import MoreVert from "@material-ui/icons/MoreVert";

const appsControlMode = APPS_CONTROL_MODES.APP;

const AppsByCategory = (props) => {
  const { classes, filteredAppsData, colors } = props;
  const [categoryObj, setCategoryObj] = useState({});
  const [categoryApps, setCategoryApps] = useState({});
  const [categories, setCategories] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [appData, setAppData] = useState({});

  //console.log(filteredAppsData);
  useEffect(() => {
    if (filteredAppsData?.length) {
      let activeCategories = {};
      const categoryList = filteredAppsData?.map((app) => {
        if (!activeCategories?.[`${app?.category?.name}`]) {
          activeCategories = {
            ...categoryObj,
            ...activeCategories,
            [app?.category?.name]: false,
          };
        }
        return app?.category?.name;
      });
      setCategories(Object.keys(activeCategories));
      setCategoryObj(activeCategories);
    }
  }, [filteredAppsData]);

  // useEffect(() => {
  //   console.log(appData);
  // }, [appData]);

  useEffect(() => {
    const itemByCategory = filteredAppsData?.reduce((acc, item) => {
      const categoryName = item?.category?.name;

      if (!acc[categoryName]) {
        acc[categoryName] = [];
      }

      acc[categoryName]?.push(item);

      return acc;
    }, {});

    setCategoryApps(itemByCategory);
  }, [filteredAppsData]);

  const handleMoreOptionClick = (e, app) => {
    setAnchorEl(e.currentTarget);
    setAppData(app);
  };

  return (
    <div className={classes.rightSect}>
      <h3
        style={{
          margin: "4px 6px 0px 16px",
        }}
      >
        {" "}
        All Categories
      </h3>
      <div style={{ marginTop: "5px" }}>
        {filteredAppsData?.length ? (
          <div>
            {categories?.map((category, index) => (
              <div>
                <div
                  style={{
                    width: "100%",
                    background: "#DE54390D",
                    cursor: "pointer",
                    padding: "1px 6px 0px 12px",
                    margin: "10px 0px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    setCategoryObj(() => ({
                      ...categoryObj,
                      [category]: !categoryObj[category],
                    }))
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        background: colors[index % colors.length].pri,
                      }}
                      className={classes.appsIcon}
                    >
                      <MdAnalytics
                        color={colors[index % colors.length].sec}
                        style={{ fontSize: "22px" }}
                      />
                    </div>
                    <h4 style={{ fontWeight: "500" }}>{category}</h4>
                  </div>
                  <div style={{ cursor: "pointer" }}>
                    {!categoryObj?.[category] ? (
                      <KeyboardArrowRight
                      // onClick={() =>
                      //   setCategoryObj(() => ({
                      //     ...categoryObj,
                      //     [category]: true,
                      //   }))
                      // }
                      />
                    ) : (
                      <KeyboardArrowDown
                      // onClick={() =>
                      //   setCategoryObj(() => ({
                      //     ...categoryObj,
                      //     [category]: false,
                      //   }))
                      // }
                      />
                    )}
                  </div>{" "}
                </div>
                <Collapse
                  in={categoryObj?.[category]}
                  timeout="auto"
                  unmountOnExit
                >
                  {categoryApps?.[category]?.length
                    ? categoryApps?.[category]?.map((app) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            margin: "1px",
                            //border: "1px solid red",
                            height: "3rem",
                            padding: "0px 15px",
                          }}
                        >
                          <Link
                            style={{ textDecoration: "none" }}
                            to={`${unprotectedUrls.RUN}/${app?.account?.slug}/${app?.slug}`}
                            target="_blank"
                          >
                            <h4 style={{ fontWeight: "500", color: "gray" }}>
                              {app?.name}
                            </h4>
                          </Link>
                          <IconButton
                            title="menuIcon"
                            style={{ padding: 7 }}
                            onClick={(e) => handleMoreOptionClick(e, app)}
                          >
                            <MoreVert style={{ fontSize: 18 }} />
                          </IconButton>{" "}
                        </div>
                      ))
                    : ""}
                </Collapse>
              </div>
            ))}
          </div>
        ) : (
          ""
        )}
      </div>
      <MenuActions
        setAnchorEl={setAnchorEl}
        anchorEl={anchorEl}
        appData={appData}
      />
    </div>
  );
};

export default AppsByCategory;

const MenuActions = ({
  setAnchorEl,
  anchorEl,
  active,
  sendMenuAction,
  appData,
}) => {
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={"screens-menu"}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        {[
          { name: "Edit App" },
          { name: "Add to Personal" },
          { name: "Add to Department" },
        ]?.map(({ name }, idx) =>
          idx !== 0 ? (
            <MenuItem
              button
              key={idx}
              onClick={(e) => {
                sendMenuAction && sendMenuAction(name);
                handleMenuClose();
              }}
              style={{ fontWeight: 300, lineHeight: "19px" }}
            >
              {name}
            </MenuItem>
          ) : (
            handleRoleActionAccess(
              { pageTitle: APPS_CONTROL_MODES.APP },
              "UPDATE",
              appData?.ownerGroup
            ) && (
              <MenuItem
                component={Link}
                style={{
                  display: "block",
                  textDecoration: "none",
                  color: "gray",
                }}
                to={`${otherProtectedUrls.EDITOR}/${
                  appData?.app || appData?.id
                }${editorNavigationUrls.UI_EDITOR}`}
              >
                {name}
              </MenuItem>
            )
          )
        )}
      </Menu>
    </div>
  );
};
