import { useState, useEffect, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Slide } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useStep } from "react-hooks-helper";
import { debounce } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";

import { useStyles } from "../AppsPortal/appsStyle";
import { appDialogModes } from "../AppsPortal/util/appLogics";
import {
  APPS_CONTROL_MODES,
  APPS_PER_PAGE,
} from "../../EditorLayout/Pages/Workflow/components/utils/constants";
import {
  rSaveAppsList,
  rSetAppsControlMode,
} from "../../../store/actions/properties";
import {
  getAppsList,
  getCategories,
} from "../../common/components/Query/AppsQuery/queryApp";
import useCustomQuery from "../../common/utils/CustomQuery";
import UserDetails from "./components/UserDetails";
import AnalyticsGraph from "./components/RecordSection";
import PortalLayout from "../PortalAppLayout/PortalLayout";

const steps = [{ id: "welcome" }, { id: "tour" }];

const appsControlMode = APPS_CONTROL_MODES.APP;

const initPaging = {
  currentPage: 1,
  hasMore: false,
  currentCategory: "All",
};
const colors = [
  { pri: "#FF4BA61F", sec: "#FF45A3" },
  { pri: "#3374F51F", sec: "#2C6FF4" },
  { pri: "#14E0AE1F", sec: "#0EDEAB" },
];
const DashboardPortal = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles(makeStyles);
  const [paging, setPaging] = useState(initPaging);
  const [categories, setCategories] = useState([]);
  const [displayToTopBtn, setDisplayToTopBtn] = useState(false);
  const [forOtherTab, setForOtherTab] = useState("first");
  const [fromLoadMore, setFromLoadMore] = useState(false);
  const [showLoadMoreButton, setLoadMoreShowButton] = useState(false);
  const [hasLoadError, setHasLoadError] = useState(false);
  const [openAppDialog, setOpenAppDialog] = useState(false);
  const [dialogAppDetails, setDialogAppDetails] = useState({});
  const [dialogAppMode, setDialogAppMode] = useState(appDialogModes.NEW);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLap, setIsLap] = useState(true);
  const [filteredAppsAndTemplatesData, setFilteredAppsAndTemplatesData] =
    useState([]);
  const [searchPerformed, setSearchPerformed] = useState(false);

  const {
    appsReducer: {
      appsAndTemplatesData,
      selectedCategory,
      appSortParams,
      openAppDetailsDialogToken,
    },
  } = useSelector((state) => state);

  const { pageSearchText } = useSelector(({ reducers }) => reducers);

  const queryObj = {
    queryKey: [
      {
        appSortParams,
        selectedCategory: paging.currentCategory,
        page: paging.currentPage,
        perPage: APPS_PER_PAGE,
      },
    ],
  };

  const runSearch = useRef(
    debounce(async (query, searchText) => {
      setIsLoading(true);
      const result = await getAppsList(query, searchText);
      setIsLoading(false);

      if (result?.data?._meta?.success) {
        //onSuccess(result);
        setFilteredAppsAndTemplatesData(result.data.data);
      } else {
        onError();
      }
    }, 1000)
  );

  useEffect(() => {
    //handleSearchChange(pageSearchText);
    setSearchPerformed(!!pageSearchText);
    if (pageSearchText) {
      runSearch.current(queryObj, pageSearchText);
    } else {
      setFilteredAppsAndTemplatesData(appsAndTemplatesData?.[appsControlMode]);
    }
  }, [pageSearchText, appsAndTemplatesData]);

  useEffect(() => {
    document.title = "Dashboard Portal";
  }, []);

  useEffect(() => {
    if (!!openAppDetailsDialogToken && !openAppDialog) {
      setOpenAppDialog(true);
      setDialogAppDetails({});
      setDialogAppMode(appDialogModes.NEW);
    }
  }, [openAppDetailsDialogToken]);
  const { navigation } = useStep({ initialStep: 0, steps });

  const handleClose = () => {
    rSetAppsControlMode();
    setOpenAppDialog(false);
  };

  const onSuccess = ({ data }) => {
    setHasLoadError(false);
    const currentListData = appsAndTemplatesData[appsControlMode] || [];
    const updatedList = [
      ...(fromLoadMore ? currentListData : []),
      ...data?.data,
    ];
    dispatch(
      rSaveAppsList({
        ...appsAndTemplatesData,
        [appsControlMode]: updatedList,
      })
    );

    const hasMore = !!data?._meta?.pagination?.next;
    setPaging({
      ...paging,
      hasMore,
    });
    setFromLoadMore(false);
    setLoadMoreShowButton(true);
  };

  const onError = () => {
    setHasLoadError(true);
  };

  useEffect(() => {
    runFetch();
  }, [
    appSortParams,
    paging.currentPage,
    paging.currentCategory,
    APPS_PER_PAGE,
    forOtherTab,
  ]);

  const runFetch = async () => {
    setIsLoading(true);
    const result = await getAppsList(queryObj, "");
    setIsLoading(false);

    if (result?.data?._meta?.success) {
      onSuccess(result);
    } else {
      onError();
    }
  };

  props = { navigation };

  const handleCategoriesListSuccess = ({ data }) => {
    const categoriesData = data?.data?.data?.map((category) => {
      return { id: category.id, name: category.name };
    });

    return setCategories(categoriesData);
  };

  /* Calling the useCustomQuery hook, which is a custom hook that is used to fetch the categories. */
  useCustomQuery({
    apiFunc: getCategories,
    queryKey: ["listCategories"],
    onSuccess: handleCategoriesListSuccess,
  });

  const openToManage = (appId) => {
    history.push(`/editor/${appId}/uieditor`);
  };

  return (
    <>
      <PortalLayout>
        <>
          {" "}
          {(!isLoading || paging.currentPage !== 1) && (
            <div>
              <UserDetails colors={colors} classes={classes} />
              <AnalyticsGraph
                categories={categories}
                colors={colors}
                classes={classes}
                appsControlMode={appsControlMode}
                filteredAppsData={filteredAppsAndTemplatesData}
              />
            </div>
          )}
          <div style={{ clear: "both" }} />
          {!isLoading && !filteredAppsAndTemplatesData?.length && (
            <div style={{ width: "100%", textAlign: "center" }}>
              <img
                src="../../../images/dashboard-no-post.svg"
                alt="No Posts"
                width={100}
                style={{ marginTop: 50 }}
              />
              {hasLoadError ? (
                <div
                  style={{
                    color: "#a12121",
                    fontWeight: 400,
                    fontStyle: "italic",
                    fontSize: 12,
                    marginTop: 20,
                  }}
                >
                  An error occured. Please check your connection and refresh.
                </div>
              ) : (
                <div style={{ color: "#091540", fontWeight: 500 }}>
                  {searchPerformed
                    ? "Record not found."
                    : ` Welcome ${
                        JSON.parse(localStorage.userInfo || "{}")?.firstName
                      }!`}
                </div>
              )}
            </div>
          )}
        </>
      </PortalLayout>
      {displayToTopBtn && (
        <Slide in={displayToTopBtn} mountOnEnter unmountOnExit>
          <div
            className={classes.toTopIcon}
            onClick={() =>
              window.document.children[0].scrollIntoView({
                block: "start",
                behavior: "smooth",
                inline: "nearest",
              })
            }
          >
            <FontAwesomeIcon
              icon={faArrowUp}
              style={{ fontSize: 20 }}
              color={"#000"}
            />
          </div>
        </Slide>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    isLoggingOut: state.auth.isLoggingOut,
    logoutError: state.auth.logoutError,
  };
}

export default connect(mapStateToProps)(DashboardPortal);
