import { Collapse, Tooltip, Typography } from "@material-ui/core";

import React, { useEffect, useState } from "react";
import { HiMiniAdjustmentsHorizontal } from "react-icons/hi2";
import { MdAnalytics } from "react-icons/md";
import { Remove } from "@material-ui/icons";
import { Menu, MenuItem } from "@material-ui/core";

import appIcon from "../../../../assets/images/applist-single-img.svg";

const QuickAccess = (props) => {
  const { categories, classes, filteredAppsData, colors } = props;
  const [categoryObj, setCategoryObj] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);

  //console.log(filteredAppsData);
  useEffect(() => {
    let activeCategories = {};
    const categoryList = categories?.map((category) => {
      activeCategories = { ...categoryObj, [category?.name]: true };
      return category;
    });
    setCategoryObj(activeCategories);
  }, [categories]);

  const handleMoreOptionClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  return (
    <div className={classes.leftSect}>
      <div
        style={{
          display: "flex",
          margin: "0px 6px 0px 16px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2
          style={{
            fontWeight: "500",
          }}
        >
          {" "}
          Quick Access
        </h2>
        <HiMiniAdjustmentsHorizontal
          onClick={(e) => handleMoreOptionClick(e)}
          color="#DE5439"
          style={{ fontSize: "20px", cursor: "pointer" }}
        />
      </div>
      <div
        style={{
          margin: "5px 16px 0px 16px",
        }}
      >
        <div>
          {filteredAppsData
            ?.filter((app, index) => {
              if (index < 5) {
                return app;
              }
            })
            ?.map((apps, index) => (
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "10px",
                    height: "50px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        background: colors[index % colors.length].pri,
                      }}
                      className={classes.appsIcon}
                    >
                      <MdAnalytics
                        color={colors[index % colors.length].sec}
                        style={{ fontSize: "22px" }}
                      />
                    </div>
                    <Tooltip title={apps.name.length <= 36 ? "" : apps.name}>
                      <Typography
                        style={{
                          //fontWeight: "500",
                          fontSize: "1rem",
                          textTransform: "capitalize",
                        }}
                      >
                        {`${apps.name.slice(0, 36)}${
                          apps.name.length <= 36 ? "" : "..."
                        }`}
                      </Typography>
                    </Tooltip>
                  </div>
                  <Remove
                    style={{
                      fontSize: "2rem",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
      <MenuActions
        setAnchorEl={setAnchorEl}
        classes={classes}
        colors={colors}
        filteredAppsData={filteredAppsData}
        anchorEl={anchorEl}
      />
    </div>
  );
};

export default QuickAccess;

const MenuActions = ({
  setAnchorEl,
  anchorEl,
  active,
  classes,
  sendMenuAction,
  colors,
  appsControlMode,
  filteredAppsData,
}) => {
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.menuBase}>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        //id={"screens-menu"}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={handleMenuClose}
        style={{ top: "4rem" }}
        PopoverClasses={classes.menuBase}
        className={classes.menuBase}
      >
        {filteredAppsData
          ?.filter((app, index) => {
            if (index > 4) {
              return app;
            }
          })
          ?.map((apps, index) => (
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "10px",
                  height: "50px",
                  width: "320px",
                  overflow: "hidden !important",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <div
                    style={{
                      background: colors[index % colors.length].pri,
                    }}
                    className={classes.appsIcon}
                  >
                    <MdAnalytics
                      color={colors[index % colors.length].sec}
                      style={{ fontSize: "22px" }}
                    />
                  </div>
                  <Tooltip title={apps.name.length <= 36 ? "" : apps.name}>
                    <h4
                      style={{
                        fontWeight: "500",
                      }}
                    >
                      {`${apps.name.slice(0, 36)}${
                        apps.name.length <= 36 ? "" : "..."
                      }`}
                    </h4>
                  </Tooltip>
                </div>
                <h1
                  style={{
                    fontWeight: "300",
                    fontSize: "2.5rem",
                    cursor: "pointer",
                  }}
                >
                  +
                </h1>
              </div>
            </div>
          ))}

        {/* {[
          { name: "Edit App" },
          { name: "Add to Personal" },
          { name: "Add to Department" },
        ].map(({ name }, idx) => (
          <MenuItem
            button
            key={idx}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: "30px",
              fontWeight: 300,
              lineHeight: "10px",
              width: "320px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <div className={classes.appsIcon}>
                <MdAnalytics color="#FF9DCB" style={{ fontSize: "22px" }} />
              </div>
              <Tooltip title={apps.name.length <= 36 ? "" : apps.name}>
                <h4
                  style={{
                    fontWeight: "500",
                  }}
                >
                  {`${apps.name.slice(0, 36)}${
                    apps.name.length <= 36 ? "" : "..."
                  }`}
                </h4>
              </Tooltip>
            </div>
            <h1
              style={{
                fontWeight: "100",
                fontSize: "5rem",
                cursor: "pointer",
              }}
            >
              -
            </h1>
            {/* <h4 style={{ fontWeight: 300 }}>{name}</h4>{" "}
            <h1 style={{ fontWeight: 300 }}>+</h1> */}
        {/*
          </MenuItem>
        ))} */}
      </Menu>
    </div>
  );
};
