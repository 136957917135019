import React from "react";
import { TableHead, Tooltip } from "@material-ui/core";
import QuickAccess from "../../AppsPortal/components/QuickAccess";
import { LuFilter } from "react-icons/lu";
import { IoFilterOutline } from "react-icons/io5";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { MdAnalytics } from "react-icons/md";
import { TbExternalLink } from "react-icons/tb";

const RecordSection = ({
  categories,
  colors,
  classes,
  appsControlMode,
  filteredAppsData,
}) => {
  return (
    <div
      style={{
        display: "flex",
        gap: "20px",
        width: "100%",
        justifyContent: "flex-end",
        marginTop: "30px",
      }}
    >
      <RecordsTable />
      <QuickAccess
        categories={categories}
        colors={colors}
        classes={classes}
        appsControlMode={appsControlMode}
        filteredAppsData={filteredAppsData}
      />
    </div>
  );
};
export default RecordSection;
const RecordsTable = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "15px",
        gap: "3px",
        width: "70%",
        borderRadius: "10px",
        background: "#FFFFFF",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "3px",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          padding: "0px 10px",
        }}
      >
        <h2 style={{ margin: "10px 0", fontWeight: "500" }}>Records</h2>
        <div
          style={{
            display: "flex",
            gap: "25px",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "3px",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <h3 style={{ margin: "10px 0", fontWeight: "500" }}>Filter</h3>
            <LuFilter size={18} />
          </div>
          <div
            style={{
              display: "flex",
              gap: "3px",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <h3 style={{ margin: "10px 0", fontWeight: "500" }}>Sort by</h3>
            <IoFilterOutline size={18} />
          </div>
        </div>
      </div>
      <ActionsTable />
    </div>
  );
};

function createData(name, calories, fat, time) {
  return { name, calories, fat, time };
}

const colors = [
  { pri: "#FF4BA61F", sec: "#FF45A3" },
  { pri: "#3374F51F", sec: "#2C6FF4" },
  { pri: "#14E0AE1F", sec: "#0EDEAB" },
];
const statusColors = [
  { name: "Pending", bg: "#EEC90540", color: "#D0AF00" },
  { name: "in-progress", bg: "#2457C140", color: "#2457C1" },
  { name: "Completed", bg: "#29CF4440", color: "#21B338" },
];
const rows = [
  createData("Staff Allowance", "Timothy", "2 minutes", "Pending"),
  createData("Authority to Travel", "Timothy", "2 minutes", "Pending"),
  createData("Staff Expense Claim", "Timothy", "2 minutes", "Pending"),
  createData("Frozen yoghurt", "Timothy", "2 minutes", "Pending"),
  createData("Staff Allowance Expenditure", "Timothy", "2 minutes", "Pending"),
  createData("Honeycomb", "Timothy", "2 minutes", "Pending"),
].sort((a, b) => (a.calories < b.calories ? -1 : 1));

const useStyles1 = makeStyles({
  table: {
    minWidth: 500,
    overflow: "auto",
  },
  container: {
    maxHeight: 440,
    overflow: "auto",
  },
  headCell: {
    borderTop: "1px solid lightgray",
    borderBottom: "1px solid lightgray",
    borderRight: "0px",
    background: "white",
    "&:first-child": {
      borderLeft: "1px solid lightgray",
      borderTopLeftRadius: "5px",
    },
    "&:last-child": {
      borderRight: "1px solid lightgray",
      borderTopRightRadius: "5px",
    },
  },
  oneHeadCell: {
    border: "1px solid lightgray",
  },
});

export function ActionsTable() {
  const classes = useStyles1();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table stickyHeader className={classes.table} aria-label="table">
        <TableHead style={{ background: "none" }}>
          <TableRow>
            <TableCell className={classes.headCell}>Name</TableCell>
            <TableCell align="right" className={classes.headCell}>
              Initiator
            </TableCell>
            <TableCell align="right" className={classes.headCell}>
              Process
            </TableCell>
            <TableCell align="right" className={classes.headCell}>
              Status
            </TableCell>
            <TableCell align="right" className={classes.headCell}></TableCell>
          </TableRow>
        </TableHead>{" "}
        <TableBody>
          {(rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows
          ).map((row, index) => (
            <TableRow key={row.name}>
              {/* <TableCell component="th" scope="row">
              {row.name}
            </TableCell> */}
              <TableCell
                style={{
                  width: 180,
                  display: "flex",
                  gap: "12px",
                  border: "0px",
                }}
                scope="row"
              >
                <div
                  style={{
                    background: colors[index % colors.length].pri,

                    width: 32,
                    minWidth: 32,
                    height: 30,
                    borderRadius: 15,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "0.5rem",
                  }}
                  //className={classes.appsIcon}
                >
                  <MdAnalytics
                    color={colors[index % colors.length].sec}
                    style={{ fontSize: "22px" }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Tooltip title={row.name?.length <= 15 ? "" : row.name}>
                    <span
                      style={{
                        fontWeight: "500",
                        margin: "0px",
                      }}
                    >
                      {`${row.name?.slice(0, 15)}${
                        row.name?.length <= 15 ? "" : "..."
                      }`}
                    </span>
                  </Tooltip>
                  <span style={{ color: "darkgray", fontSize: "0.9rem" }}>
                    {" "}
                    {"Created: 20/09/24"}
                  </span>
                </div>
              </TableCell>
              <TableCell style={{ width: 160, border: "0px" }} align="right">
                {row.calories}
              </TableCell>
              <TableCell style={{ width: 160, border: "0px" }} align="right">
                {row.fat}
              </TableCell>
              <TableCell style={{ width: 160, border: "0px" }} align="center">
                <span
                  style={{
                    background: statusColors[index % statusColors.length].bg,
                    padding: "5px 10px",
                    borderRadius: "20px",
                    color: statusColors[index % statusColors.length].color,
                  }}
                >
                  {statusColors[index % statusColors.length].name}
                </span>
              </TableCell>
              <TableCell style={{ width: 80, border: "0px" }} align="left">
                <TbExternalLink
                  size={20}
                  style={{ marginTop: "5px", cursor: "pointer" }}
                />
              </TableCell>
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              //rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              //colSpan={3}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              // SelectProps={{
              //   inputProps: { "aria-label": "rows per page" },
              //   native: true,
              // }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
