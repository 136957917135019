export const useStyles = (makeStyles) => {
  return makeStyles((theme) => ({
    root: {
      display: "flex",
    },
    // necessary for content to be below app bar

    loading: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },

    appsWrapper: {
      display: "flex",
      alignItems: "flex-start",
      background: "#FFFFFF",
      justifyContent: "space-between",
      flexWrap: "wrap",
      rowGap: 11,
      width: "100%",
      padding: "20px",
      columnGap: 6,
      [theme?.breakpoints?.down("sm")]: {
        flexDirection: "column",
      },
    },
    rightSect: {
      background: "#FFFFFF",
      border: "2px solid #F0F0F0",
      borderRadius: "10px",
      width: "55%",
      [theme?.breakpoints?.down("sm")]: {
        width: "90%",
      },
    },
    leftSect: {
      background: "#FFFFFF",
      border: "2px solid #F0F0F0",
      borderRadius: "10px",
      width: "42%",
      [theme?.breakpoints?.down("sm")]: {
        width: "90%",
      },
    },
    appsIcon: {
      width: 32,
      minWidth: 32,
      height: 30,
      //backgroundColor: "#FFE7E8",
      borderRadius: 15,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& img": {
        width: 22,
      },
    },
    menuBase: {
      overflow: "hidden !important",
      //"& div": { overflow: "hidden !important" },
      "& .MuiPopover-paper": {
        overflow: "hidden !important",
        padding: "10px",
      },
    },
    paginate: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "3em",
      marginTop: "2rem",
      "& > span": {
        color: "#2896FD",
        fontWeight: 400,
        lineHeight: "16px",
        cursor: "pointer",
        backgroundColor: "#fff",
        padding: "5px 20px",
        fontSize: 12,
        borderRadius: 20,
        boxShadow: "0 0 5px #ccc",
        zIndex: 1,
        "&:hover": {
          boxShadow: "none",
        },
      },
      "&:before": {
        content: '""',
        borderBottom: "solid 2px #eaeef2",
        width: "100%",
        position: "absolute",
        bottom: 17,
        // zIndex: -1,
      },
    },
    toTopIcon: {
      position: "fixed",
      right: 60,
      bottom: 125,
      background: "#fff",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 50,
      height: 50,
      borderRadius: "50%",
      boxShadow: "0px 1px 4px rgba(111, 111, 111, 0.25)",
      cursor: "pointer",
    },
  }))();
};
