import { useState, useEffect, useRef } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  FormControl,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import ReactSpinnerTimer from "react-spinner-timer";

import useStyles from "./components/style";
import {
  getUserGroupsAPI,
  newUserGroupAPI,
  updateUserGroupAPI,
  removeUserGroupAPI,
} from "./utils/usergroupsAPIs";
import SingleUserGroup from "./components/SingleUserGroup";
import NewUserGroupModal from "./components/NewUserGroupModal";
import { rTriggerNewUserGroupDialog } from "../../../../store/actions/properties";
import useCustomQuery from "../../../common/utils/CustomQuery";
import useCustomMutation from "../../../common/utils/CustomMutation";
import SkeletonCard from "../../components/SkeletonCard";
import { listNums } from "../../../common/utils/perPage";
import { debounce } from "lodash";
import { DEBOUNCE_TIME } from "../../../common/utils/constants";
import { errorToastify } from "../../../common/utils/Toastify";

const UserGroups = () => {
  const dispatch = useDispatch();
  const { triggerNewUserGroup } = useSelector(({ users }) => users);
  const { pageSearchText } = useSelector(({ reducers }) => reducers);

  const [isLap, setIsLap] = useState(true);
  const [modalMode, setModalMode] = useState("add");
  const [modalData, setModalData] = useState();
  const [isNewUserGroupModalVisible, setIsNewUserGroupModalVisible] =
    useState(false);
  const [newAdds, setNewAdds] = useState([]);
  const [allUserGroups, setAllUserGroups] = useState([]);
  const [filteredUserGroups, setFilteredUserGroups] = useState([]);
  const [filters, setFilters] = useState({ status: "All", search: "" });
  const [perPageArr, setPerPageArr] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [entries, setEntries] = useState(null);
  const [isSearching, setIsSearching] = useState(false);

  const classes = useStyles(); // ok

  const userGroupDebounceSearch = useRef(
    debounce(async (searchValue) => {
      if (!searchValue) {
        return;
      }

      setIsSearching(true);

      try {
        const valueSearch = await getUserGroupsAPI(null, searchValue);
        const valueSearchData = valueSearch?.data;

        setFilteredUserGroups(valueSearchData);
      } catch (error) {
        setIsSearching(false);
        errorToastify(
          "An error occured. Please try searching again after a while."
        );
      } finally {
        setIsSearching(false);
      }
    }, DEBOUNCE_TIME)
  );

  useEffect(() => {
    handleSearchChange(pageSearchText);
  }, [pageSearchText]);

  useEffect(() => {
    document.title = "Settings | User Groups";

    return () => {
      dispatch(rTriggerNewUserGroupDialog(""));
    };
  }, []);

  useEffect(() => {
    const filtr = { ...filters };
    let srch = new RegExp(filtr.search.replace(/[^\w\s]/gi, ""), "gi");
    const filtered = allUserGroups.filter(
      (f) =>
        (filtr.status === "All" || f.active === (filtr.status === "Active")) &&
        (!srch || (f.name && f.name.search(srch) !== -1))
    );

    setFilteredUserGroups(filtered);
  }, [allUserGroups, filters]);

  useEffect(() => {
    if (!!triggerNewUserGroup) {
      setIsNewUserGroupModalVisible(true);
    }
  }, [triggerNewUserGroup]);

  const onGetUserGroupsSuccess = ({ data }) => {
    setAllUserGroups(data?.data);
    setPerPage(data?._meta?.pagination?.per_page);
    setEntries(data?._meta?.pagination?.total_count);
  };

  // fetch usergroups
  const options = {
    query: {
      // population: ["users"],
      population: [{ path: "users", select: "id firstName lastName" }],
    },
  };
  const { isLoading, isFetching } = useCustomQuery({
    queryKey: ["allUserGroups", options, perPage, pageNo],
    apiFunc: getUserGroupsAPI,
    onSuccess: onGetUserGroupsSuccess,
    enabled: !!perPage,
  });

  const onAddUserGroupSuccess = ({ data }) => {
    const newData = {
      ...data.data,
      id: data.data.id,
      status: "Inactive",
    };

    let updatedGroups = [...allUserGroups];

    updatedGroups.unshift(newData);
    setAllUserGroups(updatedGroups);

    setModalMode("add");
    setModalData("");
    setIsNewUserGroupModalVisible(false);
  };

  const onUpdateUserGroupSuccess = ({ data }) => {
    const dat = data?.data;

    const updatedUserGroups = allUserGroups.filter((u) => {
      return u.id === dat.id ? data.data : u;
    });

    setAllUserGroups(updatedUserGroups);

    // setFilteredUsers(data.data);
    // dispatch({ type: FETCH_SPECIFIED_SHEET, payload: data?.data });
    // alert('SUCCESS')

    setModalMode("add");
    setModalData("");
    setIsNewUserGroupModalVisible(false);
  };

  const onDeleteUserGroupSuccess = ({ data }) => {
    const dat = data?.data;

    const updatedUserGroups = allUserGroups.filter((g) => g.id !== dat._id);

    setAllUserGroups(updatedUserGroups);

    // setFilteredUsers(data.data);
    // dispatch({ type: FETCH_SPECIFIED_SHEET, payload: data?.data });
    // alert('SUCCESS')

    setModalMode("add");
    setModalData("");
    setIsNewUserGroupModalVisible(false);
  };

  const { mutate: addUserGroup } = useCustomMutation({
    apiFunc: newUserGroupAPI,
    onSuccess: onAddUserGroupSuccess,
    retries: 0,
  });

  const { mutate: updateUserGroup } = useCustomMutation({
    apiFunc: updateUserGroupAPI,
    onSuccess: onUpdateUserGroupSuccess,
    retries: 0,
  });

  const { mutate: deleteUserGroup } = useCustomMutation({
    apiFunc: removeUserGroupAPI,
    onSuccess: onDeleteUserGroupSuccess,
    retries: 0,
  });

  const _doFilter = (filt) => {
    let filtr = { ...filters, ...filt };
    setFilters(filtr);
  };

  const handleChange = (lap) => {
    if (lap.isFinish) {
      setIsLap(false);
    }
  };

  const handlePageChange = (e) => {
    if (e.target.value) {
      setPageNo(e.target.value);
    }
  };

  const handleSearchChange = (input) => {
    userGroupDebounceSearch.current(input);

    const filtered = !!input ? filteredUserGroups : allUserGroups;

    setFilteredUserGroups(filtered);
    setPerPage((prevValue) => prevValue);
    setPageNo((prevValue) => prevValue);
  };

  const _handleSaveNewUserGroup = async (info) => {
    const resp = { status: "failed" }; // await handleUserGroupActions(info, 'add');
    if (resp.status === "success") {
      info.id = resp.data.id;
      const upd = newAdds;
      upd.unshift(info);
      setNewAdds(upd);
      setIsNewUserGroupModalVisible(false);
    }
  };

  const _handleModalDone = ({ mode, data }) => {
    if (mode === "add") {
      addUserGroup({ data });
    } else if (mode === "update") {
      updateUserGroup({ data });
    } else {
      //  just a normal close modal
      setModalMode("add");
      setModalData("");
      setIsNewUserGroupModalVisible(false);
    }
  };

  const _deleteMe = (id) => {
    deleteUserGroup({ id });
  };

  useEffect(() => {
    if (entries) setPerPageArr(listNums(entries));
  }, [entries]);

  return !isLoading && !isFetching ? (
    <div style={{ width: "100%" }}>
      <Grid container item xs={12} sm={12} direction="row" spacing={3}>
        {isSearching ? (
          <div className={classes.noRecord}>
            <Typography>
              Searching. Please wait...
              <img
                src="../../../images/loading-anim.svg"
                alt="Clone"
                width={20}
              />
            </Typography>
          </div>
        ) : (
          <>
            {!!filteredUserGroups?.length &&
              filteredUserGroups.map((data) => (
                <SingleUserGroup
                  key={data.id}
                  item={data}
                  userGroups={filteredUserGroups}
                  deleteMe={() => _deleteMe(data.id)}
                />
              ))}
            {!filteredUserGroups?.length && (
              <div className={classes.noRecord}>
                <Typography>No user group created yet.</Typography>
              </div>
            )}
            {!!filteredUserGroups?.length && (
              <Grid
                container
                style={{
                  paddingTop: 20,
                  paddingBottom: 20,
                  visibility: "visible",
                }}
                spacing={2}
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                {!!perPageArr?.length && (
                  <Grid item>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography>Rows per page:</Typography>
                      <FormControl className="perpage-dropdown">
                        <Select
                          className={classes.perPageInput}
                          defaultValue={perPage}
                          style={{
                            marginLeft: "0px",
                            marginRight: "6px",
                          }}
                          onChange={(e) => {
                            setPerPage(e.target.value);
                          }}
                          displayEmpty
                          native
                          inputProps={{
                            "aria-label": "Without label",
                            disableUnderline: true,
                          }}
                        >
                          {perPageArr?.map((num) => (
                            <option
                              style={{
                                borderBottom: "0 !important",
                                marginLeft: "6px",
                              }}
                              key={num}
                              value={num}
                            >
                              {num}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                )}
                <Grid item>
                  <Typography>{entries} entries. </Typography>
                </Grid>
                <Grid item>
                  <Typography>Showing</Typography>
                </Grid>
                <Grid item style={{ width: 80 }}>
                  <TextField
                    id="outlined-password-input"
                    type="number"
                    autoComplete="current-password"
                    variant="outlined"
                    size="small"
                    InputProps={{
                      inputProps: {
                        min: 1,
                        max: Math.ceil(entries / perPage),
                      },
                    }}
                    defaultValue={pageNo}
                    onChange={(e) => handlePageChange(e)}
                  />
                </Grid>
                <Grid item>
                  <Typography>
                    of {Math.ceil(entries / perPage)} pages
                  </Typography>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Grid>

      {isNewUserGroupModalVisible && (
        // <NewUserGroupModal closeModal={() =>setIsNewUserGroupModalVisible(false)} saveNewUserGroup={_handleSaveNewUserGroup} />
        <NewUserGroupModal closeModal={_handleModalDone} mode={modalMode} />
      )}

      {/* { isRolePermissionsVisible && rolePermissionsData &&
        <RolePermissions showModal={setIsRolePermissionsVisible} item={ activeRole } data={ rolePermissionsData } /> 
      }
      { isRoleUsersVisible && roleUsersData &&
        <RoleUsers showModal={setIsRoleUsersVisible} item={ activeRole } data={ roleUsersData } /> 
      } */}
    </div>
  ) : (
    <div>
      {isLap ? (
        <div className={classes.loadingPage}>
          <ReactSpinnerTimer
            timeInSeconds={3}
            totalLaps={1}
            isRefresh={false}
            onLapInteraction={handleChange}
          />
        </div>
      ) : (
        <SkeletonCard />
      )}
    </div>
  );
};

export default UserGroups;
