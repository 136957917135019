import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { TextField } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import DescasioLogo from "../../../../assets/images/descasio.svg";
import { withStyles } from "@material-ui/core/styles";
import switchModeBrush from "../../../../assets/images/switch-mode-brush.svg";
import searchIcon from "../../../../assets/images/search-normal-icon.svg";
import lightModeDarkMode from "../../../../assets/images/light-dark-mode.svg";
import portalMenuIcon from "../../../../assets/images/portal-menu-icon.svg";
import dropdownSelectorIcon from "../../../../assets/images/dropdown-selector.svg";
import notificationPortalIcon from "../../../../assets/images/notification-portal-icon.svg";
import usePortalStyles from "./portalstyles";
import { getHighestRole } from "../../../common/utils/userRoleEvaluation";
import { mainNavigationUrls } from "../../../common/utils/lists";
import { Redirect } from "react-router";
import { ADMIN_PORTAL_ROLES } from "../../../common/utils/userRoleEvaluation";

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -1,
    top: 7,
    padding: "0 4px",
    backgroundColor: "rgba(33, 179, 56, 1)",
    color: "rgba(255, 255, 255, 1)",
  },
}))(Badge);

export default function PortalAppToolBar({
  onHandleDrawerToggle,
  unreadNotificationCount,
}) {
  const classes = usePortalStyles();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [redirected, setRedirected] = useState(false);

  if (redirected) {
    return <Redirect to={mainNavigationUrls.APPS} />;
  }

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={onHandleDrawerToggle}
          className={classes.menuButton}
        >
          {/* this is where the icon button is */}
          <MenuIcon color="secondary" />
        </IconButton>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            flexGrow: 1,
          }}
        >
          {ADMIN_PORTAL_ROLES?.includes(getHighestRole(userInfo?.roles)) && (
            <Button
              variant="contained"
              disableElevation
              style={{
                fontFamily: "'Avenir','Inter'",
                textTransform: "none",
                borderRadius: "20px",
                backgroundColor: "rgba(41, 41, 41, 1)",
                color: "rgba(255, 255, 255, 1)",
                padding: "6px 18px 6px 18px",
              }}
            >
              Request Payslip
            </Button>
          )}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              cursor: "pointer",
            }}
          >
            <img src={portalMenuIcon} alt="portal menu" />
            <Typography variant="h6" noWrap>
              Menu
            </Typography>
            <img src={dropdownSelectorIcon} alt="dropdown menu" />
          </div>
          <div className="app-search-bar">
            <TextField
              name="app-search-text"
              variant="outlined"
              placeholder="Search"
              // value={pageSearchText}
              // onChange={(e) => dispatch(rSetPageSearchText(e.target.value))}
              style={{ flex: 1 }}
              autoComplete="off"
              autoCorrect="off"
              inputProps={{
                autoComplete: "new-password",
                form: {
                  autoComplete: "off",
                },
              }}
            />
            <img src={searchIcon} alt="search" />
          </div>
          <StyledBadge badgeContent={unreadNotificationCount}>
            <img src={notificationPortalIcon} alt="notificaton icon" />
          </StyledBadge>

          {ADMIN_PORTAL_ROLES?.includes(getHighestRole(userInfo?.roles)) && (
            <Button
              variant="contained"
              disableElevation
              style={{
                fontFamily: "'Avenir','Inter'",
                textTransform: "none",
                borderRadius: "8px",
                backgroundColor: "rgba(41, 41, 41, 0.16)",
                color: "rgba(41, 41, 41, 1)",
                padding: "7px 12px 7px 12px",
              }}
              onClick={() => {
                setRedirected(true);
              }}
            >
              Switch Mode
              <img
                src={switchModeBrush}
                alt="switch mode"
                style={{ paddingLeft: "10px" }}
              />
            </Button>
          )}

          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
              <div>
                <Typography variant="h6" noWrap style={{ maxWidth: "150px" }}>
                  {[userInfo?.firstName, userInfo?.lastName].join(" ")}
                </Typography>
                <Typography
                  variant="h6"
                  noWrap
                  style={{
                    textAlign: "right",
                    fontSize: "11px",
                    color: "rgba(171, 171, 171, 1)",
                  }}
                >
                  {getHighestRole(userInfo?.roles)}
                </Typography>
              </div>

              <Avatar alt={userInfo?.firstName}>
                {userInfo?.firstName?.charAt(0) + userInfo?.lastName?.charAt(0)}
              </Avatar>
            </div>

            <IconButton>
              <img src={lightModeDarkMode} alt="light mode dark mode" />
            </IconButton>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
}
