import React, { useState } from "react";
import { KeyboardArrowDown, KeyboardArrowRight } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { makeStyles, Tooltip, Typography } from "@material-ui/core";

const Container = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <UserDetails classes={classes} />
      <OrgDetails classes={classes} />
      <PendingTasks classes={classes} />
    </div>
  );
};
export default Container;
const UserDetails = ({ classes }) => {
  return (
    <div className={classes.userRoot}>
      <h2 className={classes.userHeadingOne}>Personal Details</h2>
      <div className={classes.deptDivOne}>
        <div className={classes.flexCol}>
          <div>
            <Typography className={classes.bottomSpacer}>Department</Typography>
            <Typography className={classes.bottomUsrTxt}>Finance</Typography>
          </div>
          <div>
            <Typography className={classes.bottomSpacer}>
              Time in Company
            </Typography>
            <Typography className={classes.bottomSpace2}>
              Less than a month
            </Typography>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>
            <Typography className={classes.bottomSpacer}>Supervisor</Typography>
            <Typography className={classes.bottomUsrTxt}>Yemi Alabi</Typography>
          </div>
          <div>
            <Typography className={classes.bottomSpacer}>Your ID</Typography>
            <Typography className={classes.bottomSpace2}>
              DES/2024/168
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

const OrgDetails = ({ classes }) => {
  const [appData, setAppData] = useState({});
  return (
    <div className={classes.orgHeader}>
      <h2 className={classes.orgDetails}>Organization Details</h2>
      <div className={classes.docWrapper}>
        <div className={classes.flexCol}>
          <div>
            <Typography className={classes.bottomSpacer}>Documents</Typography>
            <div className={classes.policyCover}>
              <Typography className={classes.textSize}>Policies</Typography>
              <h5 className={classes.eightItems}> 8 items</h5>
              {!appData?.id ? (
                <KeyboardArrowRight
                  style={{
                    fontSize: "21px",
                    cursor: "pointer",
                  }}
                  onClick={() => setAppData({ ...appData, id: true })}
                />
              ) : (
                <KeyboardArrowDown
                  style={{
                    fontSize: "21px",
                    cursor: "pointer",
                  }}
                  onClick={() => setAppData({ ...appData, id: false })}
                />
              )}
            </div>
          </div>
          <div>
            <Typography className={classes.bottomSpacer}>Documents</Typography>
            <Typography className={classes.bottomSpace2}>HMO</Typography>
          </div>
        </div>
        <div className={classes.flexCol}>
          <div>
            <Typography className={classes.bottomSpacer}>Organogram</Typography>
            <Typography className={classes.bottomUsrTxt}>
              My Organogram
            </Typography>
          </div>
          <div>
            <Typography className={classes.bottomSpacer}>Documents</Typography>
            <Typography className={classes.bottomSpace2}>HMO</Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    gap: "20px",
    width: "100%",
  },
  userRoot: {
    display: "flex",
    flexDirection: "column",
    padding: "12px",
    gap: "3px",
    width: "30%",
    borderRadius: "10px",
    background: "#FFFFFF",
  },
  userHeadingOne: {
    width: "100%",
    margin: "10px 0",
    fontWeight: "500",
  },
  deptDivOne: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  flexCol: {
    display: "flex",
    flexDirection: "column",
  },
  bottomUsrTxt: {
    margin: "4px 0",
    fontSize: "1rem",
    marginBottom: "15px",
  },
  bottomSpace2: {
    marginTop: "4px",
    fontSize: "1rem",
  },
  bottomSpacer: {
    color: "darkgray",
    fontWeight: "500",
    fontSize: "1rem",
    marginBottom: "4px",
  },
  orgHeader: {
    display: "flex",
    flexDirection: "column",
    padding: "12px",
    gap: "3px",
    width: "35%",
    borderRadius: "10px",
    background: "#FFFFFF",
  },
  orgDetails: { width: "100%", margin: "10px 0", fontWeight: "500" },
  docWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  policyCover: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "15px",
    gap: "8px",
  },
  textSize: {
    margin: "0px",
    fontSize: "1rem",
  },
  eightItems: {
    padding: "2px 6px",
    margin: "0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "25px",
    backgroundColor: "#DE543933",
  },
  pendingCover: {
    display: "flex",
    flexDirection: "column",
    padding: "12px",
    gap: "3px",
    width: "35%",
    borderRadius: "10px",
    background: "#FFFFFF",
  },
  pendingTop: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  nameTitle: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "8px",
    marginBottom: "10px",
  },
  userName: {
    padding: "2px 6px",
    margin: "0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "25px",
    fontWeight: "500",
  },
  userDays: {
    padding: "2px 6px",
    margin: "0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "25px",
    fontWeight: "500",
    backgroundColor: "#DE543933",
  },
});
const PendingTasks = ({ classes }) => {
  const pluralize = (val, text) => (val > 1 ? text + "s " : text);
  const [appData, setAppData] = useState({});
  //console.log(appData)
  const appTexts = [
    {
      title: "Cash disbursement to vendors",
      days: pluralize(1, "1 day") + " to go",
      pri: "#52DE3929",
      name: "Fatihat",
    },
    {
      title: "Leave Request",
      days: pluralize(3, "3 day") + " to go",
      pri: "#398EDE29",
      name: "Umama",
    },
    {
      title: "Federal Loan Request Form ",
      days: pluralize(5, "5 day") + " to go",
      pri: "#DEB23929",
      name: "Tobi",
    },
  ];
  const colors1 = ["lsdl", "klsdl"];
  return (
    <div className={classes.pendingCover}>
      <div className={classes.pendingTop}>
        <h2 style={{ margin: "12px 0", fontWeight: "500" }}>Pending Tasks</h2>
        <Link style={{ color: "black" }} to="/">
          See all
        </Link>
      </div>
      {appTexts?.map((appT) => (
        <div className={classes.nameTitle}>
          <Tooltip title={appT?.title?.length <= 15 ? "" : appT?.title}>
            <Typography
              style={{
                fontSize: "1rem",
                margin: "0px",
              }}
            >
              {`${appT.title?.slice(0, 15)}${
                appT.title?.length <= 15 ? "" : "..."
              }`}
            </Typography>
          </Tooltip>
          <h4
            className={classes.userName}
            style={{
              backgroundColor: appT.pri,
            }}
          >
            {" "}
            {appT.name}
          </h4>
          <h4 className={classes.userDays}> {appT.days}</h4>
          {!appData?.[appT?.name] ? (
            <KeyboardArrowRight
              style={{
                fontSize: "21px",
                cursor: "pointer",
              }}
              onClick={() => setAppData({ ...appData, [appT?.name]: true })}
            />
          ) : (
            <KeyboardArrowDown
              style={{
                fontSize: "21px",
                cursor: "pointer",
              }}
              onClick={() => setAppData({ ...appData, [appT?.name]: false })}
            />
          )}
        </div>
      ))}
    </div>
  );
};
