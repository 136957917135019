import { composeQueryString } from "../../../../common/utils/composeQueryString";
import { CustomAxios } from "../../../../common/utils/CustomAxios";

//  get list of users
export const getUsersAPI = async (options, searchValue = null) => {
  const populationQuery = [
    {
      path: "userGroups",
      select: "name",
    },
    {
      path: "lineManager",
      select: "firstName lastName",
    },
  ];

  const populationQueryString = JSON.stringify(populationQuery);

  // search for a user if a debounce search value is provided
  if (searchValue) {
    const result = await CustomAxios().get(
      `${process.env.REACT_APP_ENDPOINT}/users?search=${searchValue}&population=${populationQueryString}`
    );
    return result.data;
  }

  const { pageParam } = options || {};
  let pageNo = pageParam ?? 1;
  let per_page = 10;
  //console.log(Object.keys(options["queryKey"][1]));
  if (options?.queryKey) {
    if (
      options["queryKey"][1] &&
      Object.keys(options["queryKey"][1]).includes("perpage")
    ) {
      per_page = options["queryKey"][1]["perpage"];
      pageNo = options["queryKey"][1]["pageParam"];
    }
  }
  const queryStr =
    options?.queryKey || options?.param || options?.query
      ? composeQueryString(options)
      : composeQueryString(null);
  //console.log(queryStr);
  // if (queryStr === false) return { data: [] };

  const result =
    queryStr === false
      ? await CustomAxios().get(
          `${process.env.REACT_APP_ENDPOINT}/users?population=${populationQueryString}&page=${pageNo}&per_page=${per_page}`
        )
      : await CustomAxios().get(
          `${process.env.REACT_APP_ENDPOINT}/users${queryStr}&population=${populationQueryString}&per_page=${per_page}`
        );
  //console.log(result.data);

  return result.data;
};

// search user request
export const searchUsersAPI = async (value) => {
  //console.log(value);
  const per_page = 20;
  const result = await CustomAxios().get(
    `${process.env.REACT_APP_ENDPOINT}/users?search=${value}&selection=id+firstName+lastName+roles`
  );
  return result.data;
};

// new user request
export const newUserAPI = async ({ data }) => {
  const populationQuery = [
    {
      path: "userGroups",
      select: "name",
    },
    {
      path: "lineManager",
      select: "firstName lastName",
    },
  ];

  const populationQueryString = JSON.stringify(populationQuery);

  const result = await CustomAxios().post(
    `${process.env.REACT_APP_ENDPOINT}/users/invite?population=${populationQueryString}`,
    data
  );
  return result.data;
};

// update a user request
export const updateUserAPI = async ({ data: { id, ...data } }) => {
  const populationQuery = [
    {
      path: "userGroups",
      select: "name",
    },
    {
      path: "lineManager",
      select: "firstName lastName",
    },
  ];

  const populationQueryString = JSON.stringify(populationQuery);

  const result = await CustomAxios().put(
    `${process.env.REACT_APP_ENDPOINT}/users/${id}?population=${populationQueryString}`,
    data
  );
  return result.data;
};

// update a user request
export const removeUserAPI = async ({ data: { id, ...data } }) => {
  const result = await CustomAxios().delete(
    `${process.env.REACT_APP_ENDPOINT}/users/${id}`,
    data
  );
  return result.data;
};

// resend mail request
export const resendMailAPI = async (email) => {
  const redirectUrl = `${process.env.REACT_APP_BASE_URL}/create-password`;
  const result = await CustomAxios().post(
    `${process.env.REACT_APP_ENDPOINT}/users/invite/resend`,
    { email, redirectUrl }
  );
  return result.data;
};
