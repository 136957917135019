import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 250;

const usePortalStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    fontFamily: "'Avenir','Inter'",
    // backgroundColor: "rgba(171, 171, 171, 1)",
    "& .MuiListItemText-primary": {
      color: "rgba(171, 171, 171, 1)",
      fontFamily: "'Avenir','Inter'",
    },
    "& .MuiListItemText-secondary": {
      color: "#263238",
      fontFamily: "'Avenir','Inter'",
      fontSize: "15px",
    },
    "& .MuiListItem-button:hover": {
      backgroundColor: "rgba(222, 84, 57, 0.12)",
      borderRight: "1.5px solid red",
    },
    "& .MuiListItem-gutters": {
      padding: "10px 0px 10px 35px",
    },
    "& .MuiSvgIcon-colorSecondary ": {
      color: "rgba(41, 45, 50, 1)",
    },
    "& .MuiToolbar-regular": {
      backgroundColor: "red",
    },
  },
  drawer: {
    fontFamily: "'Avenir','Inter'",
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    backgroundColor: "#FFFFFF",
    boxShadow: "none",
    borderBottom: "1px solid #F0F0F0",
    fontFamily: "'Avenir','Inter'",
    minHeight: "75px",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,

  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#FFFFFF",
    fontFamily: "'Avenir','Inter'",
    borderRight: "none",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(6),
    // backgroundColor: "rgba(171, 171, 171, 1)",
  },
  iconStyle: {
    minWidth: "35px",
  },
}));

export default usePortalStyles;
