import React from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  portalNavigationList,
  portalSupportNavigationList,
} from "../../common/utils/lists";
import PlugLogo from "../../../assets/images/plug-logo.svg";
import DescasioLogo from "../../../assets/images/descasio.svg";
import switchModeBrush from "../../../assets/images/switch-mode-brush.svg";
import searchIcon from "../../../assets/images/search-normal-icon.svg";
import lightModeDarkMode from "../../../assets/images/light-dark-mode.svg";
import portalMenuIcon from "../../../assets/images/portal-menu-icon.svg";
import dropdownSelectorIcon from "../../../assets/images/dropdown-selector.svg";
import notificationPortalIcon from "../../../assets/images/notification-portal-icon.svg";
import logoutPortalIcon from "../../../assets/images/portal-logout-icon.svg";
import { Link, NavLink, useLocation } from "react-router-dom";
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import PortalAppToolBar from "./components/PortalAppToolBar";
import Logout from "../../Login/components/Logout/Logout";
import { handleRolePageAccess } from "../../common/utils/userRoleEvaluation";
import { useNotification } from "./utils/hooks/useNotification";

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    fontFamily: "'Avenir','Inter'",
    // backgroundColor: "rgba(171, 171, 171, 1)",
    "& .MuiListItemText-primary": {
      color: "rgba(171, 171, 171, 1)",
      fontFamily: "'Avenir','Inter'",
    },
    "& .MuiListItemText-secondary": {
      color: "#263238",
      fontFamily: "'Avenir','Inter'",
      fontSize: "15px",
    },
    "& .MuiListItem-button:hover": {
      backgroundColor: "rgba(222, 84, 57, 0.12)",
      borderRight: "1.5px solid red",
    },
    "& .MuiListItem-gutters": {
      padding: "10px 0px 10px 35px",
    },
    "& .MuiSvgIcon-colorSecondary ": {
      color: "rgba(41, 45, 50, 1)",
    },
  },
  drawer: {
    fontFamily: "'Avenir','Inter'",
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    backgroundColor: "#FFFFFF",
    boxShadow: "none",
    borderBottom: "1px solid #F0F0F0",
    fontFamily: "'Avenir','Inter'",
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,

  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#FFFFFF",
    fontFamily: "'Avenir','Inter'",
    borderRight: "none",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(6),
    // backgroundColor: "rgba(171, 171, 171, 1)",
  },
  iconStyle: {
    minWidth: "35px",
  },
}));

function PortalLayout(props) {
  const { window, children } = props;
  const { unreadNotificationCount } = useNotification();
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const isNavActive = (url) => {
    if (url === location.pathname) {
      return true;
    }
    return false;
  };

  const drawer = (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      {/* Logo Section */}
      <div
        style={{
          minHeight: "74px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "sticky",
          top: 0,
          backgroundColor: "#FFFFFF",
          zIndex: 1,
        }}
      >
        <img
          src={DescasioLogo}
          alt="logo"
          style={{
            objectFit: "contain",
          }}
        />
      </div>

      <Divider />

      {/* Scrollable Navigation Content */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          flex: 1,
        }}
      >
        <div className={classes.toolbar} />
        <List style={{ fontFamily: "'Avenir','Inter'" }}>
          {portalNavigationList.map((text, index) => (
            <Link to={text?.url} key={index}>
              <ListItem
                button
                style={
                  isNavActive(text?.url)
                    ? {
                        backgroundColor: "rgba(222, 84, 57, 0.12)",
                        borderRight: "1.5px solid red",
                      }
                    : {}
                }
              >
                <ListItemIcon className={classes.iconStyle}>
                  {text?.icon}
                </ListItemIcon>
                <ListItemText primary={text?.title} />
                <ListItemIcon className={classes.iconStyle}>
                  {text?.rightIcon}
                </ListItemIcon>
              </ListItem>
            </Link>
          ))}
        </List>

        {/* Portal Support Navigation List with Auto Margin */}
        <List style={{ marginTop: "auto", fontFamily: "'Avenir','Inter'" }}>
          {portalSupportNavigationList.map((support, index) => (
            <Link to={support?.url} key={support?.title}>
              <ListItem button>
                <ListItemIcon className={classes.iconStyle}>
                  {support?.icon}
                </ListItemIcon>
                <ListItemText
                  secondary={support?.title}
                  style={{ fontFamily: "'Avenir','Inter'" }}
                />
              </ListItem>
            </Link>
          ))}

          <Logout>
            <ListItem button>
              <ListItemIcon className={classes.iconStyle}>
                <img src={logoutPortalIcon} alt="navicon" />
              </ListItemIcon>
              <ListItemText
                secondary="Log Out"
                style={{ fontFamily: "'Avenir','Inter'" }}
              />
            </ListItem>
          </Logout>
        </List>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "35px",
            gap: "5px",
            fontFamily: "'Avenir','Inter'",
          }}
        >
          <Typography
            style={{
              fontSize: "12px",
              color: "rgba(171, 171, 171, 1)",
            }}
          >
            Powered by
          </Typography>
          <img src={PlugLogo} width="45px" alt="plug icon" />
        </div>
      </div>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <PortalAppToolBar
        onHandleDrawerToggle={handleDrawerToggle}
        unreadNotificationCount={unreadNotificationCount}
      />

      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
}

PortalLayout.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default PortalLayout;
