import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getInAppNotification } from "../../../../common/components/Query/InAppNotificaficationQuery/inAppQuery";

export const useNotification = () => {
  const [readNotificationCount, setReadNotificationCount] = useState(0);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);

  const queryKey = [
    "inAppNotificationBadgeCount",
    {
      perPage: 3,
      page: 1,
      read: "",
      sortParams: {},
    },
  ];

  const getNotificationDetails = (data) => {
    setReadNotificationCount(data?.data._meta?.pagination?.total_read);
    setUnreadNotificationCount(data?.data._meta?.pagination?.total_unread);
  };

  useQuery(queryKey, getInAppNotification, {
    cacheTime: 2 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchInterval: 3 * 60 * 1000,
    refetchOnMount: false,
    onSuccess: getNotificationDetails,
  });

  return { readNotificationCount, unreadNotificationCount };
};
